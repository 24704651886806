<template>
  <app-header/>
  <Suspense>
      <template #default>
         <app-sports/>
     </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppSports from '../../components/Pages/GamesCategorys/AppSports.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from "vue";
export default {
  components: { AppHeader, AppSports, AppFooter, AppLoading, Suspense },
   name: 'Sports'
}
</script>

<style>

</style>